
export const getTaxonomyUri = (taxonomyClass: string, data: any) => {
  let property;
  switch (taxonomyClass) {
  case "medicalproduct":
    property = "hasMedicalBrand";
    break;
  case "retailproduct":
    property = "hasRetailBrand";
    break;
  case "foodproduct":
    property = "hasFoodProductLine";
    break;
  default:
    property = null;
  }
  return property ? data?.[property]?.[0] ?? null : null;
}
