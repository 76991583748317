import { RelationElementConfiguration } from "types/appConfig";
import { ExtendedJSONSchemaDefinitionForTaxonomy } from "types/extendSchema";

import { getTaxonomyUri as getTaxonomyBrandUri } from "helpers/entityHelpers/taxonomyHelpers/getTaxonomyUri";
import {
  getTaxonomyRelationTitle
} from "helpers/entityHelpers/taxonomyHelpers/taxonomyTitleHelpers/getTaxonomyRelationTitle";
import { configurationServiceInstance } from "services/ConfigurationService";

import { UIConfigurationTemplates } from "modules/GenerateByUIConfigurationModule/UIConfiguration";
import { JSONSchemaForRelationsType } from "modules/TaxonomyModule/CommonTaxonomyFormModule/CommonTaxonomyFormModule.types";
import { ALL_PRODUCT_RELATION } from "modules/TaxonomyModule/consts";

const generateRelationsObject = (
  taxonomyClass: string,
  relations: [string, RelationElementConfiguration][],
  translations: Record<string, string>,
  formData: any
) => {
  const createJSONSchemaForRelations = (
    acc: JSONSchemaForRelationsType,
    [key, relation]: [string, RelationElementConfiguration]
  ): JSONSchemaForRelationsType => {
    const definition = configurationServiceInstance.getTaxonomyItemByName(relation.to);
    if (!definition) return acc;

    const description = translations[UIConfigurationTemplates.form[key]?.description as string];
    const title = getTaxonomyRelationTitle(taxonomyClass, key) || definition.title || relation.title || relation.to;
    const brandUri = getTaxonomyBrandUri(taxonomyClass, formData);

    return {
      ...acc,
      [key]: {
        title,
        relationInfo: relation,
        endpoint: key === ALL_PRODUCT_RELATION.HAS_NO_MARKET ? `/${brandUri}/market/` : definition.meta.endpoint,
        type: "array",
        items: { type: "string" },
        ...(description && { description }),
      },
    };
  };
  return relations.reduce(createJSONSchemaForRelations, {});
};

export const createSchemaWithRelationsProperties = (
  taxonomyClass: string,
  schema: ExtendedJSONSchemaDefinitionForTaxonomy,
  translations: Record<string, string>,
  formData: any
) => {
  const newSchema = { ...schema, title: "" };
  if (!schema.meta.relations) return newSchema;

  const relationsProperties = generateRelationsObject(taxonomyClass, Object.entries(schema.meta.relations), translations, formData);

  if (relationsProperties.hasNoMarket)
    relationsProperties.hasNoMarket.title = translations["item.form.label.ex.region"];

  return {
    ...newSchema,
    properties: {
      ...schema.properties,
      ...relationsProperties,
    },
  };
};
