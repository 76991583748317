import { PaginatedResponse } from "types/apiTypes";
import { TaxonomyItem } from "types/entities/Taxonomy";

import React, { useState } from "react";
import { Select } from "antd";
import { useTranslations } from "translations/useTranslations";

import { getUnlimitedPaginationParams } from "api/rootUrl";
import { useEndpoint } from "api/useEndpoint";

import {
  CommonTaxonomyFormModuleProps,
  JSONSchemaForRelationElementType,
} from "modules/TaxonomyModule/CommonTaxonomyFormModule/CommonTaxonomyFormModule.types";

import { useFormattedOptions } from "./hooks/useFormattedOptions";
import { useSelectModeByRelationType } from "./hooks/useSelectModeByRelationType";
import { CustomTaxonomyReferenceFieldProps } from "./CustomTaxonomyReferenceField.types";

const EMPTY_VALUE = { data: [] };

export const CustomTaxonomyReferenceField: React.FC<CustomTaxonomyReferenceFieldProps> = ({
  schema,
  value,
  onChange,
  formContext,
}) => {
  let { title, endpoint, relationInfo } = schema as JSONSchemaForRelationElementType;
  // Fix for new product creation, null id is replaced with item
  endpoint = endpoint.replace("/null/market/","/item/market/")
  
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const AdditionalPartForCustomTaxonomyReferenceField = (
    formContext?.additionalComponents as CommonTaxonomyFormModuleProps["additionalComponents"]
  )?.AdditionalPartForCustomTaxonomyReferenceField;

  const translations = useTranslations();

  const {
    isLoading,
    data: { data } = EMPTY_VALUE,
    isError,
    refetch,
  } = useEndpoint<PaginatedResponse<TaxonomyItem>>(endpoint + "?" + getUnlimitedPaginationParams());
  const formattedOptions = useFormattedOptions(data);

  const { mode, onSelectChange, addNewSelectedValue } = useSelectModeByRelationType(relationInfo, onChange, value);

  if (isError) return <div>Error with loading data.</div>;

  return (
    <div>
      <Select<string, { value: string; label: string }>
        mode={mode}
        value={value}
        placeholder={`${translations["item.form.select.placeholder"]} ${title?.toLowerCase()}`}
        loading={isLoading}
        onChange={onSelectChange}
        options={formattedOptions}
        className="w-full"
        filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
        showSearch
        allowClear
        open={menuIsOpen}
        onDropdownVisibleChange={(visible) => setMenuIsOpen(visible)}
        dropdownRender={(menu) => (
          <>
            {menu}
            {AdditionalPartForCustomTaxonomyReferenceField && (
              <div
                onKeyDown={(e) => {
                  // prevent triggering select from drawer according to https://kynetec.atlassian.net/browse/PTKA-1293
                  if (e.key === "Enter") e.stopPropagation();
                }}
              >
                <AdditionalPartForCustomTaxonomyReferenceField
                  addNewSelectedValue={addNewSelectedValue}
                  refetch={refetch}
                  relationInfo={relationInfo}
                  setMenuIsOpen={setMenuIsOpen}
                />
              </div>
            )}
          </>
        )}
      />
    </div>
  );
};
